<template>
  <div class="account-settings-info-view">
    <a-row style="height: 100%; background-color: #fff">
      <a-col :md="24" :lg="12" class="height100">
        <a-card :bordered="false" class="height100">
          <a-row>
            <a-col :span="12" :offset="3">
              <div class="account-info flex flex-jc-fs">
                <span class="line"></span>
                <p>基本信息</p>
              </div>
            </a-col>
          </a-row>

          <a-form
            :form="form"
            v-bind="layout"
            :label-col="{ ...layout.labelCol, offset: 0 }"
            :wrapper-col="{ ...layout.wrapperCol, offset: 0 }"
          >
            <a-form-item label="账号：" name="account">
              <span>{{ userInfo.account }}</span>
              <span v-if="userId"> （<OpenData v-if="userId" type="userName" :openid="userId"></OpenData>） </span>
              <!-- <a-input :disabled="true" placeholder="" v-decorator="['account']" /> -->
            </a-form-item>
            <a-form-item label="姓名：" name="name">
              <a-input placeholder="给自己起个名字吧" v-decorator="['name']" />
            </a-form-item>
            <a-form-item label="手机">
              <a-input
                placeholder="请输入手机号"
                v-decorator="['phone', { rules: [{ required: false, message: '请输入手机号！' }] }]"
              />
            </a-form-item>
            <a-form-item label="昵称">
              <a-input placeholder="给自己起个昵称吧" v-decorator="['nickName']" />
            </a-form-item>

            <a-form-item label="性别">
              <a-radio-group v-decorator="['sex', { rules: [{ required: false, message: '请选择性别！' }] }]">
                <a-radio v-for="(item, index) in sexData" :key="index" :value="item.code">{{ item.name }}</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="生日">
              <a-date-picker
                placeholder="请选择生日"
                @change="onChange"
                style="width: 100%"
                v-decorator="['birthday', { rules: [{ required: false, message: '请选择生日！' }] }]"
              />
            </a-form-item>
            <a-form-item label="邮箱" :required="false">
              <a-input
                placeholder="请输入邮箱地址"
                v-decorator="[
                  'email',
                  {
                    type: 'email',
                    message: '请输入正确的邮箱号',
                    rules: [{ required: false, message: '请输入正确的邮箱号！' }],
                  },
                ]"
              />
            </a-form-item>
            <a-form-item label="" :wrapper-col="{ ...layout.wrapperCol, offset: 3 }">
              <a-button style="margin-left: 8px" type="primary" @click="submitUserInfo">保存基本信息</a-button>
            </a-form-item>
          </a-form>
        </a-card>
      </a-col>
      <a-col :md="24" :lg="12" class="height100">
        <a-card :bordered="false" class="height100 signNameCard">
          <div class="account-center-team">
            <div class="mb-2 signNameImg">
              <img :src="userInfo.signature" class="all100" />
            </div>
            <a-button @click="$refs.XnSignNameRef.show(userInfo)">打开签名板</a-button>
            <XnSignName ref="XnSignNameRef" @successful="signSuccess" />
          </div>
        </a-card>
      </a-col>
    </a-row>
    <CropUpload ref="cropUpload" @successful="cropUploadSuccess" />
  </div>
</template>

<script>
import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { sysPersonalUpdateInfo, sysUserUpdateAvatar, userUpdateSignature } from '@/api/modular/system/userManage'
import CropUpload from '@/components/CropUpload'
import XnSignName from '@/components/XnSignName'
import store from '@/store'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import moment from 'moment'
import Vue from 'vue'
import { mapGetters } from 'vuex'
// mapActions
export default {
  components: {
    XnSignName,
    CropUpload,
    OpenData,
  },
  data() {
    return {
      // cropper
      preview: {},
      form: this.$form.createForm(this),
      avatarLoading: false,
      sexData: [],
      option: {
        img: '/avatar2.jpg',
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: false,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 180,
        autoCropHeight: 180,
        fixedBox: true,
        // 开启宽度和高度比例
        fixed: true,
        fixedNumber: [1, 1],
        // userInfo
        birthdayString: '',
      },
      layout: {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 12,
        },
      },
      userId: '',
    }
  },
  computed: {
    ...mapGetters(['avatar', 'userInfo']),
  },
  mounted() {
    this.initUserInfo()
    if (Vue.ls.get('WX_data_set')) {
      this.userId = Vue.ls.get('WX_data_set').userId
    }
  },
  methods: {
    // 头像裁剪图片回调
    cropUploadSuccess(data) {
      // 转换为file类型
      const result = new File([data.blobData], data.fileName, { type: data.fileType, lastModified: Date.now() })
      const fileData = new FormData()
      fileData.append('file', result)
      this.avatarLoading = true
      sysFileInfoUpload(fileData).then((res) => {
        const { success, message } = res
        if (success) {
          this.updateAvatar(res.data)
        } else {
          this.avatarLoading = false
          this.$message.error(message)
        }
      })
    },
    // 修改头像
    updateAvatar(data) {
      const params = {
        id: this.userInfo?.id,
        avatar: data,
      }
      sysUserUpdateAvatar(params).then((res) => {
        const { success, message } = res
        if (success) {
          this.userInfo.avatar = data
          // 更新缓存
          store.commit('SET_INFO', this.userInfo)
          store.commit('SET_AVATAR', process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + data)
          this.$message.success('头像上传修改成功')
        } else {
          this.$message.error(message)
        }
        this.avatarLoading = false
      })
    },
    // 签名板组件回调
    signSuccess(value) {
      const param = {
        signature: value,
        id: this.userInfo.id,
      }
      userUpdateSignature(param)
        .then(() => {
          this.userInfo.signature = value
          // 更新缓存
          store.commit('SET_INFO', this.userInfo)
        })
        .catch((err) => {
          this.$message.error('提交报错啦~~')
        })
    },
    // ...mapActions(['GetInfo']),
    /**
     * 初始化用户信息
     */
    initUserInfo() {
      setTimeout(() => {
        this.form.setFieldsValue({
          account: this.userInfo.account,
          birthday: this.userInfo.birthday ? moment(this.userInfo.birthday, 'YYYY-MM-DD') : undefined,
          nickName: this.userInfo.nickName,
          name: this.userInfo.name,
          sex: this.userInfo.sex.toString(),
          email: this.userInfo.email,
          phone: this.userInfo.phone,
          tel: this.userInfo.tel,
        })
        this.birthdayString = moment(this.userInfo.birthday).format('YYYY-MM-DD')
        this.option.img = process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + this.userInfo.avatar
        this.getSexData()
      }, 100)
    },
    /**
     * 日期需单独转换
     */
    onChange(date, dateString) {
      this.birthdayString = dateString
    },
    submitUserInfo() {
      const {
        form: { validateFields },
      } = this
      validateFields((err, values) => {
        if (!err) {
          values.birthday = this.birthdayString
          values.id = this.userInfo.id
          sysPersonalUpdateInfo(values).then((res) => {
            if (res.success) {
              this.$message.success('个人信息更新成功')
              store.dispatch('GetInfo').then(() => {})
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    getSexData() {
      this.sexData = this.$options.filters['dictData']('sex')
    },
    setavatar(url) {
      this.option.img = process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + url
      store.dispatch('GetInfo').then(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
// 个人信息
.account-info {
  color: #40a9ff;
  margin-bottom: 15px;
  margin-left: -6px;
  // padding-left: 55px;
  p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
  }
  span {
    height: 14px;
    width: 2px;
    background: #40a9ff;
    margin-right: 6px;
  }
}
.account-settings-info-view {
  width: 90%;
  margin: 0 auto;
  height: calc(100vh - 120px);
  .signNameCard {
    max-width: 650px;
  }
  .account-center-team {
    border: 0.5px solid;
    padding: 20px;
    height: 300px;
    position: relative;
    top: 160px;
    .signNameImg {
      height: 150px;
      width: 300px;
      border: 1px solid rgb(236 236 236);
      margin-bottom: 15px;
    }
  }
}

.account-center-avatarHolder {
  text-align: center;
  margin-bottom: 24px;

  & > .avatar {
    margin: 0 auto;
    width: 104px;
    height: 104px;
    margin-bottom: 20px;
    border-radius: 50%;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }
  }

  ::v-deep .ant-spin-nested-loading,
  ::v-deep .ant-spin-container {
    height: 100%;
  }

  .mask {
    border-radius: 50%;
    position: absolute;
    margin-top: -104px;
    width: 104px;
    height: 104px;
    background: rgba(101, 101, 101, 0.6);
    color: #ffffff;
    opacity: 0;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avatar .mask_content:hover .mask {
    opacity: 1;
  }

  .username {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 4px;
  }
}

.account-center-detail {
  p {
    margin-bottom: 8px;
    padding-left: 26px;
    position: relative;
  }

  i {
    position: absolute;
    height: 14px;
    width: 14px;
    left: 0;
    top: 4px;
    background: url(https://gw.alipayobjects.com/zos/rmsportal/pBjWzVAHnOOtAUvZmZfy.svg);
  }

  .title {
    background-position: 0 0;
  }

  .group {
    background-position: 0 -22px;
  }

  .address {
    background-position: 0 -44px;
  }
}

.teamTitle {
  font-weight: 500;
  margin-bottom: 12px;
}

.account-center-team {
  .members {
    a {
      display: block;
      margin: 12px 0;
      line-height: 24px;
      height: 24px;

      .member {
        font-size: 14px;
        line-height: 24px;
        max-width: 100px;
        vertical-align: top;
        margin-left: 12px;
        transition: all 0.3s;
        display: inline-block;
      }
    }
  }
}

.avatar-upload-wrapper {
  height: 200px;
  width: 100%;
}

.ant-upload-preview {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img,
  .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
